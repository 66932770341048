import React, { FunctionComponent } from "react"
import classNames from "classnames"
import { useIntl } from "react-intl"
import { PathPrefix } from "../../core/constants"
import {
  formatScriptureReference,
  getBookLocaliser,
  ScriptureReference
} from "../../features/scriptures/mappers/scripture-reference-mapper"
import { Link } from "../link/link"
import { ShareAndLikeButton } from "../share-and-like-button/share-and-like-button"
import styles from "./shareable-verse.module.scss"

export interface ScriptureReferenceLabelProps {
  scriptureReference: ScriptureReference
}

export const ScriptureReferenceLabel: FunctionComponent<
  ScriptureReferenceLabelProps
> = ({ scriptureReference }) => {
  const { formatMessage } = useIntl()
  const getLocalisedBook = getBookLocaliser(formatMessage)

  return <>{formatScriptureReference(scriptureReference, getLocalisedBook)}</>
}

export interface ShareableVerseProps {
  className?: string
  id?: string
  verseReference: ScriptureReference
  hasAuthor?: boolean
}

export const ShareableVerse: FunctionComponent<ShareableVerseProps> = ({
  className,
  id,
  verseReference,
  hasAuthor
}) => {
  const verseLink = `${PathPrefix.Scriptures}/${verseReference.slug}`
  const authorStyle = hasAuthor ? styles.shareableVerse : styles.noAuthor

  return (
    <>
      <div className={classNames(authorStyle, className)}>
        <Link to={verseLink}>
          <ScriptureReferenceLabel scriptureReference={verseReference} />
        </Link>
      </div>
      <div className={styles.flexRowsBreak}></div>
      <div className={classNames(styles.shareButtonWrapper, className)}>
        <ShareAndLikeButton
          className={styles.icons}
          id={id}
          contentType="devotional"
        />
      </div>
    </>
  )
}
